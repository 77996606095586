











import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  BAvatar,
  BBadge, BButton, BCard, BCardTitle, BCol,
  BDropdown,
  BDropdownItem, BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BPagination, BRow, BSpinner
} from "bootstrap-vue";
import {VueGoodTable} from 'vue-good-table'
import {VForm} from "@/global";


@Component({
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BSpinner
  }
})
export default class Roles extends Vue {
  $refs!: {
    createForm: VForm
  }
}
